import React, { useEffect, useRef } from "react";
import "./App.css";

function App() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Failed to autoplay:", error);
      });
    }
  }, []);
  return (
    <>
      <div className="background-container">
        <img
          src="https://storage.googleapis.com/3d-container/theapei/Layer%201.png"
          className="img-coming"
          alt="apehouse"
        />
      </div>
      <div className="parent">
        <div className="sub">
          <video
            id="loader-vid"
            autoplay
            ref={videoRef}
            muted
            loop
            playsinline
            src="https://storage.googleapis.com/3d-container/theapei/APEi_Coinv2_1.webm"
            className="json-icon"
            alt="apehouse"
          />
          <img
            src="https://storage.googleapis.com/3d-container/theapei/Coming%20Soon.png"
            className="coming-soon"
            alt="apehouse"
          />
          <img
            src="https://storage.googleapis.com/3d-container/theapei/Vector%20Smart%20Object.png"
            className="ape-chain"
            alt="apehouse"
          />
        </div>
      </div>
    </>
  );
}

export default App;
